<template lang="pug">
	section#tabelaUsers
		h2 Base de Clientes
		table
			thead
				td Nome
				td E-mail
				td Telefone
				td CPF/CNPJ
				td Tipo Pagamento
				td Cidade
				td Frases 
			tbody(v-for="customer, index in customers")
				td {{customer.name}}
				td {{customer.email}}
				td {{formatPhone(customer.phone)}}
				td {{formatDocument(customer.document)}}
				td {{formatPaymentType(customer.paymentType)}}
				td {{customer.city}}
				td
					button(@click="openModal(index)")
						FontAwesomeIcon(:icon="icons.faCircleInfo")
		AsideInfos(:content="filteredCustomer")
</template>

<script>
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import AsideInfos from '@sections/Painel/AsideInfos/AsideInfos.vue'

export default {
	name: "section-tabela-users",
	components: {
		AsideInfos
	},
	data(){
		return {
			icons: {
				faCircleInfo,
			},
			ativo: null,
			customers: []
		}
	},
	computed: {
		filteredCustomer() {
			if(this.customers.length > 0 && this.ativo != null)
				return this.customers[this.ativo].domains
			return {}
		}
	},
	mounted() {
		this.loadCustomers()
	},
	methods: {
		openModal(index) {
			this.ativo = index
			this.$store.commit('toggleAside', 'asideInfos')
		},
		loadCustomers() {
			this.$axios.get('customers/')
				.then(response => {
					this.customers = response.data
				})
		},
		formatDocument(document) {
			if (!document) return '';

			const numericValue = document.replace(/\D/g, '');

			if (numericValue.length === 11) {
				return numericValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
			} else if (numericValue.length === 14) {
				return numericValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
			}

			return document;
		},
		formatPhone(phone) {
			if (!phone) return '';

			const numericValue = phone.replace(/\D/g, '');

			if (numericValue.length === 11) {
				return numericValue.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
			} else if (numericValue.length === 10) {
				return numericValue.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
			}

			return phone;
		},
		formatPaymentType(type) {
			const types = {
				credit_card: 'Cartão de Crédito',
				bank_slip: 'Boleto',
				pix: 'Pix',
			};

			return types[type] || type;
		},
	}
}
</script>

<style lang="stylus" scoped src="./TabelaUsers.styl"></style>
